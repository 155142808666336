import React from "react";
import { useTheme } from "../../context/ThemeContext";

interface PhotoshopLogoProps {}

export const PhotoshopLogo = ({ ...props }: PhotoshopLogoProps) => {
  const { activeTheme } = useTheme();
  return (
    <>
      <div className="logo-container">
        <svg
          width="100"
          height="100"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M36.3334 7C21 8.66667 8.6667 21 7.00003 36C5.33336 51.6667 14.3334 65.6667 28 71C29 71.3333 30 70.6667 30 69.3333V64C30 64 28.6667 64.3333 27 64.3333C22.3334 64.3333 20.3334 60.3333 20 58C19.6667 56.6667 19 55.6667 18 54.6667C17 54.3333 16.6667 54.3333 16.6667 54C16.6667 53.3333 17.6667 53.3333 18 53.3333C20 53.3333 21.6667 55.6667 22.3334 56.6667C24 59.3333 26 60 27 60C28.3334 60 29.3334 59.6667 30 59.3333C30.3334 57 31.3334 54.6667 33.3334 53.3333C25.6667 51.6667 20 47.3333 20 40C20 36.3333 21.6667 32.6667 24 30C23.6667 29.3333 23.3334 27.6667 23.3334 25.3333C23.3334 24 23.3334 22 24.3334 20C24.3334 20 29 20 33.6667 24.3333C35.3334 23.6667 37.6667 23.3333 40 23.3333C42.3334 23.3333 44.6667 23.6667 46.6667 24.3333C51 20 56 20 56 20C56.6667 22 56.6667 24 56.6667 25.3333C56.6667 28 56.3334 29.3333 56 30C58.3334 32.6667 60 36 60 40C60 47.3333 54.3334 51.6667 46.6667 53.3333C48.6667 55 50 58 50 61V69.6667C50 70.6667 51 71.6667 52.3334 71.3333C64.6667 66.3333 73.3334 54.3333 73.3334 40.3333C73.3334 20.3333 56.3334 4.66667 36.3334 7Z"
            fill={activeTheme === "light" ? "#2F2F3A" : "#ffffff"}
          />
        </svg>

        <p style={{ color: activeTheme === "light" ? "#2F2F3A" : "#ffffff" }}>
          Git
        </p>
      </div>
    </>
  );
};

export default PhotoshopLogo;
